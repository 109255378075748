<template>
  <v-row justify="center">
    <v-dialog
      v-model="show"
      scrollable
      persistent
      max-width="800px"
    >
      <v-card>
        <v-toolbar dark :color="color.theme">
          <v-toolbar-title>{{ $t("smartinput.datagoogledrive") }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="$emit('closedialog')">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-layout class=" ma-6">
          <v-flex class="mt-1">
            <v-chip
              class="white--text"
              prepend-icon="$vuetify"
              :color="color.theme"
            >
              {{totalfile}} {{$t('file')}}
            </v-chip>
            <v-chip
              class="white--text ml-2"
              prepend-icon="$vuetify"
              :color="color.theme"
            >
             {{ totalfolder }} {{$t('folder')}}
            </v-chip>
          </v-flex>
          <v-flex class="text-right">
            <v-btn
              @click="fn_reconnect_ggdrive()"
              :color="color.theme"
              class="ml-n1 mr-n2 white--text"
            >
            {{$t('anotheraccount')}}
          </v-btn>
          </v-flex>
        </v-layout>
        <v-card-text style="height: 590px;" class="pa-6">
          <v-layout lg10>
            <!-- {{ listdirectory_parent }} -->
            <v-flex v-if="createprogress === true">
              <v-layout justify-center>
                <br />
                <v-progress-circular
                  class="mt-6 mb-6 pt-6 pb-6"
                  :size="50"
                  :dark="color.darkTheme"
                  :color="color.theme"
                  indeterminate
                >
                <span :style="colorProgress">&nbsp; &nbsp; รอสักครู่</span>
              </v-progress-circular>
              </v-layout>
            </v-flex>
            <v-flex v-else>
              <v-flex>
                  <v-chip :color="color.theme" text-color="white">
                    {{  name_connect }}
                  </v-chip>
                </v-flex>
              <v-list
                nav
                dense
                v-if="listdirectory.length > 0"
                style="max-height:500px;"
                class="overflow-y-auto"
              >                
                <!-- ย้อนกลับ -->
                <!-- <v-layout row wrap class="pa-4"> -->
                <v-flex v-if="createprogress === false">
                  <v-list nav dense v-if="listdirectory_parent.length > 0">
                    <v-layout row wrap>
                      <v-flex lg12 sm12 xs10 class="mt-1">
                        <v-chip :color="color.theme" text-color="white">
                          <v-btn
                            @click="fn_back_to_parent()"
                            style="cursor: pointer; background-color: transparent !important;"
                            icon
                            color="white"
                            class="ml-n1 mr-n2"
                          >
                            <v-icon>mdi-arrow-left-circle</v-icon>
                          </v-btn>
                          <!-- <span class="ml-4">{{
                            parentname.length >= 30
                              ? parentname.substring(0, 30) + "..."
                              : parentname
                          }}</span> -->
                        </v-chip>
                      </v-flex>
                    </v-layout>
                  </v-list>
                </v-flex>
              <!-- </v-layout> -->
                <!-- </v-layout> -->
                <br />
                <!-- {{ listmove }} -->
                <v-list-item-group
                  v-model="item"
                  :color="color.theme"
                  @change="clicker"
                >
                  <!-- <v-list-item-group v-model="item" :color="color.theme"> -->
                  <!-- {{listdirectory}} -->
                  <!-- //! ของเดิมใช้ folder_id -->
                  <!-- //? ใช้ @click bind moveto กับ id -->
                  <!-- {{ moveto }} -->

                  <!-- @click="item.data_type === 'folder' ? querydirectories(item) : ''" -->
                  <v-list-item
                    v-for="(item, i) in listdirectory"
                    :key="i"
                    @dblclick="item.data_type === 'folder' ? querydirectories(item) : ''"
                  >
                    <v-list-item-action>
                      <v-checkbox
                        v-model="listmove"
                        :value="item"
                        :color="color.theme"
                      ></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-icon>
                      <v-icon
                        v-if="item.data_type === 'folder'"
                        :color="
                          item.folder_status === 'M' ? 'green' : '#ffc107'
                        "
                        large
                        >folder</v-icon
                      >
                      <v-icon
                        v-else
                        :color="
                          fn_seticon(item.data_name.split('.')[1])[1]
                        "
                        large
                        >{{ fn_seticon(item.data_name.split('.')[1])[0] }}</v-icon
                      >
                    </v-list-item-icon>
                    <v-list-item-content>
                      <!-- //! ของเดิมใช้ folder_name -->
                      <v-list-item-title class="ml-2">{{
                        item.data_name
                      }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action v-if="item.permission === 'Y'">
                      <v-chip x-small class="py-2">
                        {{ $t("uniquefoldername") }}
                      </v-chip>
                    </v-list-item-action>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              <v-list nav dense v-else>
                <v-layout row wrap>
                  <v-flex lg12 sm12 xs10 class="mt-1">
                    <v-chip :color="color.theme" text-color="white">
                      <v-btn
                        :disabled="enableback"
                        @click="fn_back_to_parent()"
                        style="cursor: pointer; background-color: transparent !important;"
                        icon
                        color="white"
                        class="ml-n1 mr-n2"
                      >
                        <v-icon>mdi-arrow-left-circle</v-icon>
                      </v-btn>
                      <span class="ml-4"> {{ parentname }}</span>
                    </v-chip>
                    <!-- </v-subheader> -->
                  </v-flex>
                </v-layout>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="text-center">{{
                      $t("movefiles.nofolder")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-flex>

          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-checkbox
            class="pt-5 mt-n2 mb-n2"
            v-model="selectedAll"
            :label="$t('fileduplicate.selectall')"
            :color="color.theme"
          />
          <v-spacer></v-spacer>
          <v-btn
           class="white--text"
           :color="color.theme"
            @click="fn_permission_folder()"
            :disabled="createprogress"
            >{{$t('logo_feature.change_consent')}}</v-btn
          >
        </v-card-actions>
        <dialogalertduplicateggdrive
        :show="opendialogalertduplicateggdrive"
        @closedialog="fn_closedialog"
        @copy="opendialogalertduplicateggdrive=false"
        @skip="opendialogalertduplicateggdrive=false"
        :listmove="listmove"
        :listmove_unduplicate="listmove_unduplicate"
        :listmove_duplicate="listmove_duplicate"
      ></dialogalertduplicateggdrive>
      </v-card>

      <!-- </v-card> -->
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import { log } from 'console';
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import VueCookies from "vue-cookies";
const dialogalertduplicateggdrive = () => import("../optional/dialog-alert-duplicate-file-ggdrive.vue");

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  props: ["show"],
  components: { 
    dialogalertduplicateggdrive 
  },
  data() {
    return {
      name_connect:"",
      listdirectory_parent:[],
      show_selectall:false,
      show_clear:false,
      show_menu:false,
      statusmutipledownload:false,
      totaldata: 0,
      totalfile: 0,
      totalfolder: 0,
      status_file:"",
      listmove_unduplicate:[],
      listmove_duplicate:[],
      opendialogalertduplicateggdrive:false,
      listmove: [],
      item: "",
      createprogress: false,
      enableback: false,
      parentname: "",
      listdirectory: [],
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      dataprocesslog: [],
      page: 1,
      headerTH: [
        // {
        //   text: "#",
        //   align: "center",
        //   value: "type",
        //   width: "7%",
        //   sortable: false,
        // },
        {
          text: "admin.name",
          align: "",
          value: "name_th",
          width: "25%",
          sortable: false,
        },
        {
          text: "admin.system",
          align: "center",
          value: "system_business_name",
          width: "25%",
          sortable: false,
        },
        {
          text: "admin.edit_date",
          align: "center",
          value: "process_dtm",
          width: "25%",
          sortable: false,
        },
        {
          text: "admin.consent",
          align: "center",
          value: "process_name",
          width: "25%",
          sortable: false,
        },
      ],
      headerEN: [
        // {
        //   text: "#",
        //   align: "center",
        //   value: "type",
        //   width: "7%",
        //   sortable: false,
        // },
        {
          text: "admin.name",
          align: "",
          value: "name_en",
          width: "25%",
          sortable: false,
        },
        {
          text: "admin.system",
          align: "center",
          value: "system_business_name",
          width: "25%",
          sortable: false,
        },
        {
          text: "admin.edit_date",
          align: "center",
          value: "process_dtm",
          width: "25%",
          sortable: false,
        },
        {
          text: "admin.consent",
          align: "center",
          value: "process_name",
          width: "25%",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "service",
      "color",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    pageCount: {
      get() {
        let l = this.countdata;
        let s = this.size;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },
            // สำหรับการเลือกทั้งหมด
    selectedAll: {
      set(val) {
        this.listmove = [];
        if (val) {
          for (let i = 1; i <= this.listdirectory.length; i++) {
            this.listmove.push(this.listdirectory[i - 1]);
          }
        }
      },
      get () {
        // console.log("this.listmove.length",this.listmove.length);
        // console.log("this.listdirectory.length",this.listdirectory.length);
        // if (this.show === false) {
        //   return false;
        // } else if (this.listmove.length === this.listdirectory.length) {
        //   return true;
        // }
        this.listmove = [];
      },
    },

    paginatedData() {
      console.log("paginatedData()");
      const start = (this.page - 1) * this.size;
      const end = start + this.size;
      return this.dataprocesslog.slice(start, start + this.size);
    },
    headerTable() {
      return (
        "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;"
      );
    },
    headerPage() {
      return (
        "color:" + this.color.theme + ";" + "font-size: 18px; font-weight: 600;"
      );
    },
    colorVapp() {
      return "background:" + this.color.BG + ";";
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },
  },
  watch: {
    show: {
      immediate: true,
      handler(value) {
        if (value) {
          this.name_connect = VueCookies.get("gg_connect")
          this.listdirectory_parent = [];
          this.listmove = [];
          this.get_data_from_googledrive();
        }
      },
    },
  },
  methods: {
    // เซ็ต icon
    fn_seticon(parameter) {
      let dataicon;
      if (parameter === "xlsx" || parameter === "xls") {
        dataicon = ["mdi-file-excel-outline", "green"];
      } else if (parameter === "pptx" || parameter === "ppt") {
        dataicon = ["mdi-file-powerpoint-outline", "orange"];
      } else if (parameter === "docx" || parameter === "doc") {
        dataicon = ["mdi-file-word-outline", "primary"];
      } else if (parameter === "zip") {
        dataicon = ["mdi-zip-box-outline", "blue-grey"];
      } else if (parameter === "pdf") {
        dataicon = ["mdi-file-pdf-outline", "red"];
      } else if (parameter === "xml") {
        dataicon = ["mdi-file-code-outline", "orange"];
      } else if (parameter === "txt" || parameter === "txt") {
        dataicon = ["mdi-note-text-outline", "blue-grey"];
      } else if (
        parameter === "jpeg" ||
        parameter === "jpg" ||
        parameter === "png" || 
        parameter === "gif"
      ) {
        dataicon = ["mdi-file-image-outline", "blue-grey"];
      } else if (
        parameter === "avi" ||
        parameter === "mpeg" ||
        parameter === "mov" ||
        parameter === "mp4" ||
        parameter === "mkv" ||
        parameter === "wmv" ||
        parameter === "3gp" ||
        parameter === "flv"
      ) {
        dataicon = ["mdi-file-video-outline", "purple"];
      } else if (parameter === "shortcut") {
        dataicon = ["mdi-file-link-outline", "pink"];
      } else {
        dataicon = ["mdi-file-outline", "black"];
      }
      return dataicon;
    },
    fn_closedialog(){
      this.opendialogalertduplicateggdrive = false;
      this.listmove = [];
      this.$emit("closedialog");
    },
    fn_reconnect_ggdrive(){
      // this.$emit("closedialog");
      this.$emit("closedialogandreconnect");
    },
    async fn_permission_folder() {
      if(this.listmove.length > 0){
        this.listmove_unduplicate = [];
        this.listmove_duplicate = [];
        console.log("loop");
        this.opendialogalertduplicateggdrive = true;
      }else{
        Toast.fire({
            icon: "warning",
            title: this.$t('nochoosefilemigrate'),
        });
      }
      // for(let i=0; i < this.listmove.length; i++){
      //   let payload = {
      //       folder_id: this.dataCitizenProfile.my_folder_id,
      //       data_type: this.listmove[i].data_type,
      //       data_name: this.listmove[i].data_name
      //   }
      //   let auth = await gbfGenerate.generateToken();
      //   console.log("auth", auth.code);
      //   await this.axios
      //   .post(
      //       process.env.VUE_APP_SERVICE_UPLOAD_API +
      //       "/api/v1/check_duplicate_data",
      //       payload,
      //       { headers: { Authorization: auth.code } }
      //   )
      //   .then((response) => {
         
      //       if (response.data.status === "OK") {
      //         console.log("responsedก่อนstatusok", response);
      //           if(response.data.message === 'duplicate filename'){
      //             // this.migrat_duplicate(this.listmove[i])
      //             this.listmove_duplicate.push(this.listmove[i])
      //           }else{
      //             // this.migrat_unduplicate(this.listmove[i])
      //             this.listmove_unduplicate.push(this.listmove[i])
      //           }
      //       }
      //   });
      //   // console.log("i = ",i);
      //   // if(i == this.listmove.length - 1){
      //   //   console.log("this.listmove_duplicate.length",this.listmove_duplicate.length);
      //   //   if(this.listmove.length > 0){
      //   //   // if(this.listmove_duplicate.length > 0){
      //   //     this.opendialogalertduplicateggdrive = true;
      //   //   }
      //   // }
      // }

    },
    async migrat_duplicate(data){
      let payload = {
          folder_id: this.dataCitizenProfile.my_folder_id,
          user_id: this.dataCitizenProfile.user_id,
          account_id: this.dataAccountId,
          data_id: data.data_id,
          data_type: data.data_type,
          file_duplicate: "copy",
        };
        let auth = await gbfGenerate.generateToken();
        console.log("auth", auth.code);
        this.axios
          .post(
            process.env.VUE_APP_MIGRATE +
              "/api/google/drive/backup",
            payload,
            { headers: { Authorization: auth.code } }
          )
          .then((response) => {
            console.log("responsedก่อนstatusok", response);
            if (response.data.status === "OK") {
              console.log("responsed ok", response);
            }
          });
    },
    async migrat_unduplicate(data){
      let payload = {
          folder_id: this.dataCitizenProfile.my_folder_id,
          user_id: this.dataCitizenProfile.user_id,
          account_id: this.dataAccountId,
          data_id: data.data_id,
          data_type: data.data_type,
          file_duplicate: "unique foldername",
        };
        let auth = await gbfGenerate.generateToken();
        console.log("auth", auth.code);
        this.axios
          .post(
            process.env.VUE_APP_MIGRATE +
              "/api/google/drive/backup",
            payload,
            { headers: { Authorization: auth.code } }
          )
          .then((response) => {
            console.log("responsedก่อนstatusok", response);
            if (response.data.status === "OK") {
              console.log("responsed ok", response);
            }
          });
    },
    formatdatetime(_datetime) {
      // console.log(this.rootfile);
      if (_datetime === "" || _datetime === "-" || _datetime === undefined) {
        return "-";
      } else {
        let dateyear =
          _datetime.split("")[0] +
          _datetime.split("")[1] +
          _datetime.split("")[2] +
          _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];
        let hour = _datetime.split("")[8] + _datetime.split("")[9];
        let minute = _datetime.split("")[10] + _datetime.split("")[11];
        let second = _datetime.split("")[12] + _datetime.split("")[13];

        return (
          dateday +
          "/" +
          datemonth +
          "/" +
          dateyear +
          " " +
          hour +
          ":" +
          minute +
          ":" +
          second
        );
      }
    },
    async get_data_from_googledrive() {
      this.listdirectory = [];
      this.totaldata = 0;
      this.totalfile = 0;
      this.totalfolder = 0;
      this.createprogress = true;
      let payload = {
        account_id: this.dataAccountId,
        data_id: "root",
      };
      let auth = await gbfGenerate.generateToken();
      console.log("auth", auth.code);
      this.axios
        .post(
          process.env.VUE_APP_MIGRATE + "/api/google/drive/files",
          payload,
          { headers: { Authorization: auth.code } }
        )
        .then((response) => {
          console.log("responsedก่อนstatusok", response);
          if (response.data.status === "OK") {
            VueCookies.set("gg_connect",response.data.email_connect)
            this.createprogress = false;
            console.log("responsedstatus ok", response);
            this.totaldata = response.data.count;
            this.totalfile = response.data.count_file;
            this.totalfolder = response.data.count_folder;
            // this.listdirectory = response.data.data
            // console.log("this.listdirectory",this.listdirectory);

            for (let i = 0; i < response.data.data.length; i++) {
              let dataFileAndFolder = {};

              dataFileAndFolder["createdTime"] =
                response.data.data[i]["createdTime"];
              dataFileAndFolder["data_id"] = response.data.data[i]["data_id"];
              dataFileAndFolder["data_name"] =
                response.data.data[i]["data_name"];
              dataFileAndFolder["data_type"] =
                response.data.data[i]["data_type"];
              dataFileAndFolder["file_extension"] =
                response.data.data[i]["file_extension"];
              dataFileAndFolder["mimeType"] = response.data.data[i]["mimeType"];
              dataFileAndFolder["modifiedTime"] =
                response.data.data[i]["modifiedTime"];
              dataFileAndFolder["must_export"] =
                response.data.data[i]["must_export"];
              dataFileAndFolder["ownedByMe"] =
                response.data.data[i]["ownedByMe"];
              dataFileAndFolder["owners"] = response.data.data[i]["owners"];
              dataFileAndFolder["size"] = response.data.data[i]["size"];

              this.listdirectory.push(dataFileAndFolder);
            }
          }
        });
    },
    async querydirectories(item) {
      this.listdirectory = [];
      this.createprogress = true;
      let payload = {
        account_id: this.dataAccountId,
        data_id: item.data_id,
      };
      let auth = await gbfGenerate.generateToken();
      console.log("auth", auth.code);
      this.axios
        .post(
          process.env.VUE_APP_MIGRATE + "/api/google/drive/files",
          payload,
          { headers: { Authorization: auth.code } }
        )
        .then((response) => {
          console.log("responsedก่อนstatusok", response);
          if (response.data.status === "OK") {
            VueCookies.set("gg_connect",response.data.email_connect)
            this.createprogress = false;
            console.log("responsedstatus ok", response);
            if(this.listdirectory_parent.length === 0){
              this.listdirectory_parent.push("root")
            }else{
              this.listdirectory_parent.push(item.data_id)
            }
            // this.listdirectory = response.data.data
            // console.log("this.listdirectory",this.listdirectory);

            for (let i = 0; i < response.data.data.length; i++) {
              let dataFileAndFolder = {};

              dataFileAndFolder["createdTime"] =
                response.data.data[i]["createdTime"];
              dataFileAndFolder["data_id"] = response.data.data[i]["data_id"];
              dataFileAndFolder["data_name"] =
                response.data.data[i]["data_name"];
              dataFileAndFolder["data_type"] =
                response.data.data[i]["data_type"];
              dataFileAndFolder["file_extension"] =
                response.data.data[i]["file_extension"];
              dataFileAndFolder["mimeType"] = response.data.data[i]["mimeType"];
              dataFileAndFolder["modifiedTime"] =
                response.data.data[i]["modifiedTime"];
              dataFileAndFolder["must_export"] =
                response.data.data[i]["must_export"];
              dataFileAndFolder["ownedByMe"] =
                response.data.data[i]["ownedByMe"];
              dataFileAndFolder["owners"] = response.data.data[i]["owners"];
              dataFileAndFolder["size"] = response.data.data[i]["size"];

              this.listdirectory.push(dataFileAndFolder);
            }
          }
        });
    },
    async fn_back_to_parent(){
      this.listdirectory = [];
      this.listdirectory_parent.pop();
      console.log("this.listdirectory",this.listdirectory_parent);
      let index = this.listdirectory_parent.length
      console.log("index",index);
      console.log("index1 ",this.listdirectory_parent[index -1] );
      this.createprogress = true;
      let payload = {
        account_id: this.dataAccountId,
        data_id: index === 0 ? "root" : this.listdirectory_parent[index -1] ,
      };
      let auth = await gbfGenerate.generateToken();
      console.log("auth", auth.code);
      this.axios
        .post(
          process.env.VUE_APP_MIGRATE + "/api/google/drive/files",
          payload,
          { headers: { Authorization: auth.code } }
        )
        .then((response) => {
          console.log("responsedก่อนstatusok", response);
          if (response.data.status === "OK") {
            VueCookies.set("gg_connect",response.data.email_connect)
            this.createprogress = false;
            console.log("responsedstatus ok", response);
            for (let i = 0; i < response.data.data.length; i++) {
              let dataFileAndFolder = {};

              dataFileAndFolder["createdTime"] =
                response.data.data[i]["createdTime"];
              dataFileAndFolder["data_id"] = response.data.data[i]["data_id"];
              dataFileAndFolder["data_name"] =
                response.data.data[i]["data_name"];
              dataFileAndFolder["data_type"] =
                response.data.data[i]["data_type"];
              dataFileAndFolder["file_extension"] =
                response.data.data[i]["file_extension"];
              dataFileAndFolder["mimeType"] = response.data.data[i]["mimeType"];
              dataFileAndFolder["modifiedTime"] =
                response.data.data[i]["modifiedTime"];
              dataFileAndFolder["must_export"] =
                response.data.data[i]["must_export"];
              dataFileAndFolder["ownedByMe"] =
                response.data.data[i]["ownedByMe"];
              dataFileAndFolder["owners"] = response.data.data[i]["owners"];
              dataFileAndFolder["size"] = response.data.data[i]["size"];

              this.listdirectory.push(dataFileAndFolder);
            }
          }
        });
    },
    clicker() {},
  },
  mounted() {
    this.name_connect = VueCookies.get("gg_connect");
  }
  
};
</script>

<style></style>
